<template>
  <div class="flex_row searchDate">
    <dog-select
      class="searchDate__select"
      :options="typeOptions"
      v-model="dateType"
      :clearable="false"
      :width="60"
      placeholder="选择日期精度"
      @input="selectType"
    ></dog-select>
    <el-date-picker
      class="searchDate__datePicker"
      :class="{ daterange: ['daterange', 'monthrange'].includes(dateType) }"
      :type="dateType"
      :key="dateType"
      v-model="dateValue"
      placeholder="选择日期"
      :format="dateType === 'week' ? 'yyyy 年第 WW 周' : ''"
      :picker-options="pickerOptions"
      :start-placeholder="
        (dateType === 'daterange' && '开始日期') ||
        (dateType === 'monthrange' && '开始月份') ||
        ''
      "
      :end-placeholder="
        (dateType === 'daterange' && '截止日期') ||
        (dateType === 'monthrange' && '截止月份') ||
        ''
      "
      :clearable="false"
      @change="selectDate"
    >
    </el-date-picker>
  </div>
</template>

<script>
  import { timeFormat } from '@/utils/methods/common';

  export default {
    name: 'searchDate',
    data() {
      return {
        dateType: 'date',
        typeOptions: [
          { label: '日', value: 'date' },
          { label: '周', value: 'week' },
          { label: '月', value: 'month' },
          { label: '年', value: 'year' },
          { label: '日-日', value: 'daterange' },
          { label: '月-月', value: 'monthrange' }
        ],
        dateValue: new Date()
      };
    },
    computed: {
      pickerOptions() {
        return {
          firstDayOfWeek: 1,
          shortcuts:
            (this.dateType === 'daterange' && [
              {
                text: '近7天',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 1000 * 60 * 60 * 24 * 6);
                  picker.$emit('pick', [start, end]);
                }
              },
              {
                text: '近30天',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 1000 * 60 * 60 * 24 * 29);
                  picker.$emit('pick', [start, end]);
                }
              },
              {
                text: '近90天',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 1000 * 60 * 60 * 24 * 89);
                  picker.$emit('pick', [start, end]);
                }
              }
            ]) ||
            (this.dateType === 'date' && [
              {
                text: '今天',
                onClick(picker) {
                  picker.$emit('pick', new Date());
                }
              },
              {
                text: '昨天',
                onClick(picker) {
                  const date = new Date();
                  date.setTime(date.getTime() - 1000 * 60 * 60 * 24);
                  picker.$emit('pick', date);
                }
              },
              {
                text: '前天',
                onClick(picker) {
                  const date = new Date();
                  date.setTime(date.getTime() - 1000 * 60 * 60 * 24 * 2);
                  picker.$emit('pick', date);
                }
              }
            ]) ||
            []
        };
      }
    },
    methods: {
      selectType() {
        if (['date', 'week', 'month', 'year'].includes(this.dateType)) {
          this.dateValue = '';
        } else {
          this.dateValue = [];
        }
        this.$emit('input', null);
      },
      selectDate() {
        let dateValue = null;
        switch (this.dateType) {
          case 'date':
            dateValue = [
              timeFormat(this.dateValue, 'YYYY-MM-DD'),
              timeFormat(
                new Date(this.dateValue).getTime() + 1000 * 60 * 60 * 24,
                'YYYY-MM-DD'
              )
            ];
            break;
          case 'week':
            const firstDate = new Date(this.dateValue).setDate(
              this.dateValue.getDate() - this.dateValue.getDay() + 1
            );
            dateValue = [
              timeFormat(firstDate, 'YYYY-MM-DD'),
              timeFormat(firstDate + 1000 * 60 * 60 * 24 * 7, 'YYYY-MM-DD')
            ];
            break;
          case 'month':
            dateValue = [
              timeFormat(this.dateValue, 'YYYY-MM') + '-01',
              timeFormat(
                new Date(this.dateValue).setMonth(
                  this.dateValue.getMonth() + 1
                ),
                'YYYY-MM'
              ) + '-01'
            ];
            break;
          case 'year':
            dateValue = [
              timeFormat(this.dateValue, 'YYYY') + '-01-01',
              timeFormat(
                new Date(this.dateValue).setYear(
                  this.dateValue.getFullYear() + 1
                ),
                'YYYY'
              ) + '-01-01'
            ];
            break;
          case 'daterange':
            dateValue = [
              timeFormat(this.dateValue[0], 'YYYY-MM-DD'),
              timeFormat(
                new Date(this.dateValue[1]).setDate(
                  this.dateValue[1].getDate() + 1
                ),
                'YYYY-MM-DD'
              )
            ];
            break;
          case 'monthrange':
            dateValue = [
              timeFormat(this.dateValue[0], 'YYYY-MM') + '-01',
              timeFormat(
                new Date(this.dateValue[1]).setMonth(
                  this.dateValue[1].getMonth() + 1
                ),
                'YYYY-MM'
              ) + '-01'
            ];
            break;
          default:
            dateValue = [
              timeFormat(this.dateValue[0], 'YYYY-MM-DD'),
              timeFormat(
                new Date(this.dateValue[1]).setDate(
                  this.dateValue[1].getDate() + 1
                ),
                'YYYY-MM-DD'
              )
            ];
            break;
        }
        this.$emit('input', dateValue);
      }
    }
  };
</script>

<style lang="scss" scoped>
  @include b(searchDate) {
    @include e(select) {
      // width: 60px;
      /deep/ .el-input__inner {
        padding-right: 15px;
        padding-left: 10px;
      }
      /deep/ .el-input__suffix {
        right: 0;
      }
    }
    @include e(datePicker) {
      position: relative;
      top: 1px;
      width: 0;
      flex: 1;
      &.daterange {
        padding: 3px;
      }
      /deep/ .el-range__close-icon {
        display: none;
      }
      /deep/ .el-range-input {
        width: 42%;
      }
    }
  }
</style>
