<template>
  <div class="flex_column accessStatistics">
    <dog-search
      ref="search"
      :config="searchConfig"
      @search="search"
      :query="query"
    ></dog-search>
    <div class="flex_1 accessStatistics__graph">
      <div class="accessStatistics__tag">
        <div class="accessStatistics__tag__item" v-for="tag in totalTag">
          <div>
            <svg-icon :icon-class="tag.icon"></svg-icon>
            <span>{{ totalData[tag.prop] }}</span>
          </div>
          <div>{{ tag.label }}</div>
        </div>
      </div>
      <echarts
        ref="accessLine"
        :config="accessLine"
        v-loading="isLoading"
      ></echarts>
    </div>
  </div>
</template>

<script>
  import searchDate from '@/components/searchDate';
  import companyService from '@/api/service/company';
  import echarts from '@/components/echarts';
  import statisticsService from '@/api/service/statistics';
  import { timeFormat } from '@/utils/methods/common';

  export default {
    name: 'accessStatistics',
    components: {
      echarts
    },
    data() {
      return {
        query: {},
        searchConfig: [
          {
            type: 'select',
            label: '所属公司',
            key: 'companyId',
            attrs: {
              service: this.getCompanyList,
              clearable: false,
              'select-first': true
            }
          },
          {
            key: 'dateValue',
            type: 'custom',
            label: '日期',
            component: searchDate,
            attrs: {
              default: [
                timeFormat(new Date(), 'YYYY-MM-DD'),
                timeFormat(
                  new Date().getTime() + 1000 * 60 * 60 * 24,
                  'YYYY-MM-DD'
                )
              ]
            }
            // size: 'long'
          }
        ],
        accessLine: require('./accessLine'),
        totalTag: [
          {
            label: '总访问量',
            icon: 'eye',
            prop: 'visits'
          },
          {
            label: '总活跃度',
            icon: 'fire',
            prop: 'active'
          }
        ],
        totalData: {},
        isLoading: false
      };
    },
    mounted() {},
    methods: {
      getCompanyList() {
        return companyService.getOwnCompanyEnum().then((res) => {
          if (!res[0]) {
            this.$message.error('请先维护公司信息！');
            return res;
          }
          this.query.companyId = res[0].id;
          this.getData();
          return res;
        });
      },
      getData() {
        this.$refs.search.search();
      },
      search(query) {
        this.isLoading = true;
        statisticsService
          .getAccessStatistics({
            ...query,
            dateStart: query.dateValue[0],
            dateEnd: query.dateValue[1],
            dateValue: null
          })
          .then((res) => {
            this.totalData = res.total;
            this.$refs.accessLine.echarts.setOption({
              dataset: {
                source: res.list
              }
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    }
  };
</script>

<style lang="scss" scoped>
  @include b(accessStatistics) {
    @include e(graph) {
      position: relative;
    }
    @include e(tag) {
      position: absolute;
      top: 30px;
      right: 30px;
      display: flex;
      font-size: 13px;
      @include e(item) {
        & + & {
          margin-left: 20px;
        }
        & > div {
          &:first-child {
            display: flex;
            align-items: center;
            font-weight: bold;
            .svg-icon {
              position: relative;
              top: -3px;
              width: 20px;
              height: 20px;
              margin-left: 5px;
              vertical-align: top;
            }
          }
          &:last-child {
            color: #717780;
          }
        }
      }
    }
  }
</style>
